import { T } from '@transifex/react';
import { Button } from '@wfp/ui';

export const ErrorPage = () => {
  const clearLocalStorage = () => {
    localStorage.clear();
  };

  return (
    <div>
      <h2>
        <T _str="Something went wrong." />
      </h2>
      <br />

      <Button kind="secondary" onClick={clearLocalStorage}>
        <T _str="Throw away unsynced data" />
      </Button>
      <br />
    </div>
  );
};
